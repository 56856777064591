
import useProposal from '@/types/Proposal';
import { ref, defineComponent, SetupContext, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { IProposal, IProposalStatus, IProposalStatusHistory } from '@/types/Interfaces'
import StatusHistoryForm from '../StatusHistoryForm.vue'
import useProposalStatusHistory from '@/types/ProposalStatusHistory';

import {formatDate} from '@/utils';
import useFlashMessage from '@/types/FlashMessage';
export default defineComponent({
  props: {
    
  },
  components: {
    StatusHistoryForm
  },
  setup(props: any, context: SetupContext) {
    const route = useRoute();
    const proposalId = parseInt(route.params.id.toString())
    const {
      setMessage
    } = useFlashMessage();

    const {
      getProposal
    } = useProposal();

    const {
      createProposalStatusHistory      
    } = useProposalStatusHistory();

    const data = ref<IProposal | null>(null);
    
    onMounted(async () => {
      if (isNaN(proposalId)) return;
      
      data.value = await getProposal(proposalId);
      
    })
    const onApprove = async (history: IProposalStatusHistory) => {
      try {
        const status = await createProposalStatusHistory(history);
        setMessage({ type: 'success', message: '承認しました'})
        data.value = await getProposal(proposalId)
      } catch (err) {
        console.error(err)
        
      }
    }
    return {
      data,
      onApprove,
      formatDate,
    }
  }
})
