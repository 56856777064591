<template>
  <div v-if="data">
    <div
      v-if="data.waiting_approval"
    >
      <status-history-form
        :proposalId="data.id"
        :currentStatusId="data.status.id"
        :statusOptions="data.next_status"
        @close="() => {}"
        @change="onApprove"
        :dismissable="false"
      ></status-history-form>
      <div>
        <table class="base text-sm" >
        <thead>
          <tr class="">
            <th class="">紹介ID</th>
            <th>紹介されたお客様</th>
            <th>紹介したオーナー様</th>
            <th>ステータス</th>
            <th>進行スケジュール</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ data.id }}
            </td>
            <td>
              {{ data.client_name }}
            </td>
            <td>
              {{ data.owner_name }}
            </td>
            <td>
              {{ data.status ? data.status.name : '-' }}
            </td>
            <td>
              <div class="flex items-center text-xs">
                <div
                  v-for="(s, i) in data.history.filter(s => s.before_status  )"
                  :key="i"
                  class=""
                  
                >
                  <div
                    :style="`background-color: ${s.before_status.color}`"
                    class="table-cell align-middle w-16 px-2 rounded-lg text-white h-24"
                  >
                    <div>
                      {{ s.before_status.tag }}
                    </div>
                    <div>
                      {{ s.before_status.name }}
                    </div>
                    
                  </div>
                  <!-- <div
                    class="bg-blue-600 table-cell align-middle w-16 px-2 rounded-lg text-white h-24"
                    v-else>
                    開始
                  </div> -->
                  <table class="w-full text-xs text-left">
                    <tbody>
                      <!-- <tr>
                        <th>開始</th>
                        <td>{{ formatDate(s.transition_date, 'M/D') }}</td>
                      </tr> -->
                      <tr>
                        <td colspan="2" class="text-center">{{ data.history[i] ? formatDate(data.history[i].transition_date, 'M/D') : 'Null' }}</td>
                      </tr>
                      
                    </tbody>
                   
                  </table>
                  
                </div>
                <div
                  v-if="data.status"
                  class=""
                >
                  
                  <div
                    :style="`background-color: ${data.status.color}`"
                    class="table-cell align-middle w-16 px-2 rounded-lg text-white h-24"
                  >
                    <div
                      class="bg-red-600 rounded text-white"
                      v-if="data.waiting_approval">
                      承認待ち
                    </div>
                    <div>
                      {{ data.status.tag }}
                    </div>
                    <div>
                      {{ data.status.name }}
                    </div>
                  </div>
                  <table class="w-full text-xs text-left">
                    <tbody>
                     
                      <tr>
                        <td class="text-center" colspan="2">現在</td>
                      </tr>
                      
                    </tbody>
                   
                  </table>
                  
                </div>
                
                
              </div>
            </td>
           
          </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div
      v-else
    >
      承認待ちではありません。
    </div>
  </div>
</template>
<script lang="ts">
import useProposal from '@/types/Proposal';
import { ref, defineComponent, SetupContext, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { IProposal, IProposalStatus, IProposalStatusHistory } from '@/types/Interfaces'
import StatusHistoryForm from '../StatusHistoryForm.vue'
import useProposalStatusHistory from '@/types/ProposalStatusHistory';

import {formatDate} from '@/utils';
import useFlashMessage from '@/types/FlashMessage';
export default defineComponent({
  props: {
    
  },
  components: {
    StatusHistoryForm
  },
  setup(props: any, context: SetupContext) {
    const route = useRoute();
    const proposalId = parseInt(route.params.id.toString())
    const {
      setMessage
    } = useFlashMessage();

    const {
      getProposal
    } = useProposal();

    const {
      createProposalStatusHistory      
    } = useProposalStatusHistory();

    const data = ref<IProposal | null>(null);
    
    onMounted(async () => {
      if (isNaN(proposalId)) return;
      
      data.value = await getProposal(proposalId);
      
    })
    const onApprove = async (history: IProposalStatusHistory) => {
      try {
        const status = await createProposalStatusHistory(history);
        setMessage({ type: 'success', message: '承認しました'})
        data.value = await getProposal(proposalId)
      } catch (err) {
        console.error(err)
        
      }
    }
    return {
      data,
      onApprove,
      formatDate,
    }
  }
})
</script>